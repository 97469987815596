import * as React from 'react'
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

// Components
import { HeroBanner } from '../../Components/Common/HeroBanner'
import { ContentContainer } from '../../Components/Common/ContentContainer'
import { Collapsible } from '../../Components/Common/Collapsible'


const FAQsWrapper = styled.div`
  aside {
    .faq-aside--title {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    a {
      color: var(--nearlyBlue);
      font-size: 16px;
      line-height: 28px;
      font-family: ${props => props.theme.fonts.din};
      display: block;
      text-transform: capitalize;
    }
  }
  main {
    .faq-section {
      padding-top: 50px;
      &:first-of-type {
        padding-top: 0px;
      }
      h2 {
        font-size: 32px;
        margin-bottom: 25px;
        text-transform: capitalize;
      }
    }
  
    .collapsible {
        .title-container-alt {
            padding:15px;
            .title {
                text-transform: none;
            }
        }
        .content-intro {
            margin-bottom:0px;
        }
    }
  }

`


const FAQSPage: React.FC = (props) => {

	const posts = useStaticQuery(graphql`
    query {
       wordpress {
        tags(first:100) {
          edges {
            node {
              slug
              name
              fAQs {
                nodes {
                  title
                  content
                }
              }
            }
          }
        }
      }
    }
  `);

	const FaqsData = posts?.wordpress?.tags?.edges

	function camelize(str) {
		return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		}).replace(/\s+/g, '');
	}
	const [postType, setPostType] = React.useState('News');

	const onTabClick = (e) => {
		e.preventDefault();
		const target = e.target;
		const formattedValue = camelize(target.innerHTML.toLowerCase())
		const tabs = Array.from(document.querySelectorAll('.tab-list-item a'));

		if (!target.classList.contains('active')) {
			tabs.map(tab => tab.classList.remove('active'));
			target.classList.add('active')
		}
		setPostType(formattedValue);
	}

	return (
		<>
			<HeroBanner pageSlug="faqs" hasTabs={true} onTabClick={onTabClick} />
			<ContentContainer>
				<FAQsWrapper className="with-sidebar">
					<aside className="faqs__sections">
						<h3 className="faq-aside--title">FAQs Sections</h3>

						{
							FaqsData.map((faq, index) => (
								<a href={`#${faq.node.slug}`} key={`faq-title-${index}`}>{faq.node.name}</a>
							))
						}
					</aside>
					<main>

						{
							FaqsData.map((faq, index) => (
								<div key={`faq-section-${index}`} id={faq.node.slug} className="faq-section">
									<h2>{faq.node.name}</h2>
									{
										faq.node.fAQs.nodes.map((content, index) => (
											<Collapsible key={`faq-subsection-${index}`} title={content.title} alt altArrow>
												<div className="content-inner">
													<div className="inner-content">
														<div className="content-intro" dangerouslySetInnerHTML={{ __html: content.content }}></div>
													</div>
												</div>
											</Collapsible>
										))
									}
								</div>
							))
						}
					</main>
				</FAQsWrapper>
			</ContentContainer>
		</>
	)

}

export default FAQSPage